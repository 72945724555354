<template>
  <div class="flex h-full items-center tabular-nums" :class="params.justifyStart ? 'justify-start' : 'justify-end'">
    <span v-if="numberValue">
      {{ numberValue }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { rounded } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const numberValue = computed(() => (props.params.value ? props.params.value.toLocaleString() : ''));
</script>
